import consumer from "./consumer"

const chatChannel = consumer.subscriptions.create("ChatChannel", {
  connected() {
    console.log("Connected to the chat!");
  },

  disconnected() {
    console.log("Disconnected from the chat.");
  },

  received(data) {
    console.log("Received something!", data);
  },



  speak: function(content) {
    return this.perform('speak', { message: content });
  }
});

window.chatChannel = chatChannel;



