// Entry point for the build script in your package.json

// import "@hotwired/turbo-rails"
// desabilitando turbo



import "./controllers"
import * as bootstrap from "bootstrap"

import './menu-projetos.js';

import * as echarts from 'echarts';
window.echarts = echarts;

import "./src/jquery"
import "./src/jquery-ui"


import './autocomplete';


import "./channels"


import { createConsumer } from '@rails/actioncable'

createConsumer();
