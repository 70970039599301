$(document).ready(function () {
  $("#project_city").autocomplete({
    source: function (request, response) {
      $.ajax({
        url: "/autocomplete",
        dataType: "json",
        data: {
          term: request.term,
        },
        success: function (data) {
          response(
            $.map(data, function (item) {
              return {
                label: item.name + " - " + item.state.abbreviation,
                value: item.name + " - " + item.state.abbreviation,
                id: item.id,
              };
            })
          );
        },
      });
    },
    minLength: 2,
    select: function (event, ui) {
      $("#project_city").val(ui.item.label);
      $("#project_city_id").val(ui.item.id);
      return false;
    },
  });
});
