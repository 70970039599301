document.addEventListener("DOMContentLoaded", function(){
  const projectRows = document.querySelectorAll('.project-row');

  projectRows.forEach((row) => {
    row.addEventListener('click', (event) => {
      const url = event.currentTarget.dataset.url;
      window.location.href = url;
    });
  });
});
